/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import ResponsiveAutoHeight from 'responsive-auto-height';
import focusLock from 'dom-focus-lock';

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Equalize height
if (document.querySelectorAll('.match-height').length) {
	new ResponsiveAutoHeight('.match-height');
}

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav-toggler'), navigation = document.querySelector('.navigation-wrapper'), navigationContainer = document.querySelector('.navigation');
if (navigationToggler && navigation && navigationContainer) {
	const toggle = checked => {
		navigationToggler.checked = checked;
		navigation.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigationContainer);
		} else {
			focusLock.off(navigationContainer);
		}
	};

	navigation.addEventListener('click', e => {
		if (!navigationContainer.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Lightgallery
(async () => {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.documentElement, {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: false,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
})();

// Project filter
const projectFilter = document.getElementById('project-filter');
if (projectFilter) {
	var disciplineFilterEmptyText = projectFilter.querySelector('.dropdown-list').getAttribute('data-empty-text');
	var disciplineFilterTitle = projectFilter.querySelector('.dropdown-list dt');
	var disciplineFilterItem = projectFilter.querySelectorAll('.dropdown-list input');
	var projects = document.querySelectorAll('.project');
	var resetFilter = projectFilter.querySelector('.project-filter__reset');

	projects.forEach(project => {
		if (project.classList.contains('is-initial-hidden')) {
			project.classList.remove('is-initial-hidden');
			project.classList.add('is-hidden');
			setTimeout(() => {
				project.style.display = "none";
			}, 300);
		}
	});

	// Refresh in case of height change
	setTimeout(() => {
		AOS.refresh();
	}, 300);

	disciplineFilterItem.forEach(el => {
		el.addEventListener("change", () => {
			var title = el.getAttribute('data-title');
			var filterValue = el.getAttribute('value');

			if (el.checked) {
				disciplineFilterTitle.innerHTML = title;

				projects.forEach(project => {
					if (project.getAttribute('data-filter') === filterValue) {
						project.classList.remove('is-hidden');
						setTimeout(() => {
							project.style.display = "block";
						}, 300);
					} else {
						project.classList.add('is-hidden');
						setTimeout(() => {
							project.style.display = "none";
						}, 300);
					}
				});
			}

			// Refresh in case of height change
			setTimeout(() => {
				AOS.refresh();
			}, 300);
		});
	});

	resetFilter.addEventListener("click", (e) => {
		disciplineFilterItem.forEach(el => {
			el.checked = false;
		});

		projects.forEach(project => {
			project.classList.remove('is-hidden');
			setTimeout(() => {
				project.style.display = "block";
			}, 300);
		});

		disciplineFilterTitle.innerHTML = disciplineFilterEmptyText;

		e.preventDefault();
	});
}

// Highlight swiper
var highlighedSlider = document.querySelectorAll('.overview-block.is-slider, .overview-block.is-testimonial-slider');
if (highlighedSlider.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		highlighedSlider.forEach(el => {
			const columnBlock = el.querySelector('.columns'),
				swiperItem = el.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div'),
				container = el.querySelector('.container');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			container.appendChild(swiperButtonPrev);
			container.appendChild(swiperButtonNext);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			var slidesPerView = 3,
				spaceBetween = 48,
				breakpoints = {
					768: {
						slidesPerView: 1
					},
					1024: {
						slidesPerView: 2
					}
				};

			if (el.classList.contains("is-testimonial-slider")) {
				slidesPerView = 5;
				breakpoints = {
					432: {
						slidesPerView: 2
					},
					768: {
						slidesPerView: 3
					},
					1024: {
						slidesPerView: 4
					}
				};
			}

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: slidesPerView,
				breakpoints: breakpoints,
				spaceBetween: spaceBetween,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				}
			});
		});
	})();
}

// file uploades
var inputs = document.querySelectorAll('.fileupload input');
Array.prototype.forEach.call(inputs, function (input) {
	var label = input.nextElementSibling;

	input.addEventListener('change', function (e) {
		var fileName = '';
		if (this.files && this.files.length > 1)
			fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
		else
			fileName = e.target.value.split('\\').pop();

		console.log(label);

		if (fileName)
			label.innerHTML = fileName;
	});
});

// Dropdown input
var dropdownInputs = document.querySelectorAll('.select');
if (dropdownInputs.length) {

	dropdownInputs.forEach(dropdown => {
		var inputs = dropdown.querySelectorAll('input[type=radio]');
		var dropdownToggler = dropdown.querySelector('.dropdown__toggler');
		var dropdownInput = dropdown.querySelector('.dropdown__input');

		inputs.forEach(input => {

			input.addEventListener('change', (e) => {
				dropdownToggler.checked = false;
				if (input.checked == true) {
					dropdownInput.setAttribute('data-value', input.value);
				}
			});
		});
	});
}

// Jump to submitted form
document.querySelectorAll('.content-block .form').forEach(el => {
	var form = el.querySelector('form'),
		anchor = el.querySelector('.anchor'),
		button = el.querySelector('.button');

	if (!anchor) {
		anchor = el.getAttribute('id');
	}
	else {
		anchor = anchor.getAttribute('id');
	}

	form.setAttribute('action', form.getAttribute('action') + '#' + anchor);

	form.addEventListener('submit', function () {
		button.classList.add('is-loading');
	});
});